<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>{{
        currentProject.name 
      }}</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <div class="detail-wrapper">
          <div class="item-i detail-i">
            <div class="item-title">
              基本数据
            </div>
            <div class="item-content detail-content">
              <div class="num-wrapper">
                <p class="num">{{currentProject.fileAmount}}</p>
                <p class="num-label">标准要求文档数量</p>
              </div>
              <div class="num-wrapper">
                <p class="num">{{currentProject.standardAmount}}</p>
                <p class="num-label">涉及标准</p>
              </div>
              <div class="num-wrapper">
                <p class="num">{{currentProject.clauseAmount}}</p>
                <p class="num-label">涉及条目</p>
              </div>
            </div>
          </div>
            
        </div>
        <div class="item-wrapper">
          <div class="item-i">
            <div class="item-title">
              上传进度
            </div>
            <div class="item-content">
              <i-circle
              :size="150"
              :trail-width="4"
              :stroke-width="5"
              :percent="getRatio(currentProject.uploadedFile,currentProject.fileAmount)"
              stroke-linecap="square"
              stroke-color="coral">
              <div class="circle-inner-wrapper">
                  <p class="num2">{{currentProject.uploadedFile}}</p>
                  <p class="label2">已上传文件</p>
                  <p class="label3">
                      总占总文件
                      <i>{{getRatio(currentProject.uploadedFile,currentProject.fileAmount)}}%</i>
                  </p>
              </div>
          </i-circle>
            </div>
          </div>
        </div>
        <div class="item-wrapper">
          <div class="item-i">
            <div class="item-title">
              审核进度
            </div>
            <div class="item-content">
              <i-circle
              :size="150"
              :trail-width="4"
              :stroke-width="5"
              :percent="getRatio(currentProject.everVerifiedFile,currentProject.uploadedFile)"
              stroke-linecap="square"
              :stroke-color="colorList[1]">
              <div class="circle-inner-wrapper">
                  <p class="num2">{{currentProject.everVerifiedFile}}</p>
                  <p class="label2">审核过的文件</p>
                  <p class="label3">
                      占已上传文件
                      <i>{{getRatio(currentProject.everVerifiedFile,currentProject.uploadedFile)}}%</i>
                  </p>
              </div>
          </i-circle>
          <i-circle
              :size="150"
              :trail-width="4"
              :stroke-width="5"
              :percent="getRatio(currentProject.verifiedFile,currentProject.fileAmount)"
              stroke-linecap="square"
              :stroke-color="colorList[1]">
              <div class="circle-inner-wrapper">
                  <p class="num2">{{currentProject.verifiedFile}}</p>
                  <p class="label2">审核合格文件</p>
                  <p class="label3">
                      占总文件
                      <i>{{getRatio(currentProject.verifiedFile,currentProject.fileAmount)}}%</i>
                  </p>
              </div>
          </i-circle>
            </div>
          </div>
        </div>
        <div class="detail-wrapper">
          <div class="item-i">
            <div class="item-title">
              确认进度
            </div>
            <div class="item-content">
              <i-circle
              :size="150"
              :trail-width="4"
              :stroke-width="5"
              :percent="getRatio(currentProject.everConfirmedFile,currentProject.verifiedFile)"
              stroke-linecap="square"
              :stroke-color="colorList[2]">
              <div class="circle-inner-wrapper">
                  <p class="num2">{{currentProject.everConfirmedFile}}</p>
                  <p class="label2">确认过的文件</p>
                  <p class="label3">
                      占已审核文件
                      <i>{{getRatio(currentProject.everConfirmedFile,currentProject.verifiedFile)}}%</i>
                  </p>
              </div>
          </i-circle>
          <i-circle
              :size="150"
              :trail-width="4"
              :stroke-width="5"
              :percent="getRatio(currentProject.confirmedFile,currentProject.fileAmount)"
              stroke-linecap="square"
              :stroke-color="colorList[2]">
              <div class="circle-inner-wrapper">
                  <p class="num2">{{currentProject.confirmedFile}}</p>
                  <p class="label2">确认合格的文件</p>
                  <p class="label3">
                      占总文件
                      <i>{{getRatio(currentProject.confirmedFile,currentProject.fileAmount)}}%</i>
                  </p>
              </div>
          </i-circle>
          <div class="major-wrapper">
            <p class="major-title">各专业已确认数量与应确认数量比值</p>
            <div class="major-inner-wrapper">
              <i-circle
              v-for="(m,index) in currentProject.major"
              :key="index"
              :size="100"
              :trail-width="4"
              :stroke-width="5"
              :percent="getRatio(m.confirmed,m.should)"
              stroke-linecap="square"
              :stroke-color="colorList[index]">
              <div class="circle-inner-wrapper">
                  <p class="num3"><i>{{getRatio(m.confirmed,m.should)}}%</i></p>
                  <p class="label3">{{m.name}}</p>
              </div>
          </i-circle>
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import fileList from "@/components/Common/FileList.vue";

let userToken = localStorage.getItem("current_user_token");
// let authData = JSON.parse(localStorage.getItem("authData"));


const CL = [
  "#c1232b",
  "#27727b",
  "#fcce10",
  "#e87c25",
  "#b5c334",
  "#fe8463",
  "#9bca63",
  "#fad860",
  "#f3a43b",
  "#60c0dd",
  "#d7504b",
  "#c6e579",
  "#f4e001",
  "#f0805a",
  "#26c0c0"
];

export default {
  name: "projectProgress",
  components: {
    // fileList,
  },
  props: {
    projectId: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      currentProject: {
        major:{}
      },
      loadingStatus: false,
      colorList:CL
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "projectStatistics");
      form.append("project_id", this.projectId);
      this.loadingStatus = true;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/project_manage", form, config)
        .then((response) => {
          this.loadingStatus = false;
          if (response.data.stat === "ok") {
            this.currentProject = response.data.res_record;
          } else {
            this.$Message.error(response.data.errorInfo);
          }
        })
        .catch((error) => {
          this.$Message.error(error + "未知错误！");
        });
    },
    getRatio(s,m){
      if(m===0||s==0){
        return 0;
      }else{
        return Math.round(s/m*100);
      }
    }
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
  display: flex;
  flex-wrap: wrap;
}

.item-wrapper{
  width:600px;
  padding: 10px;
}

.detail-wrapper{
  width:1200px;
  padding: 10px;
}

.item-i{
  background-color: #fafafa;
  border-radius: 5px;
  height: 300px;
  border:1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
}

.detail-i{
  height:200px;
}

.item-title{
  color:#eee;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px;
  height:38px;
  background: #CC9756;
  border-bottom:1px solid #ddd;
}

.item-content{
  display: flex;
  height:260px;
  justify-content:space-around;
  align-items: center;
}

.major-wrapper{
  height:250px;
  width:60%;
}

.major-title{
  line-height:50px;
  text-align: center;
}

.major-inner-wrapper{
  height:200px;
  display: flex;
  justify-content:space-around;
  align-items: center;
  flex-wrap: wrap;
}

.detail-content{
  height:160px;
}

.num-wrapper{
  text-align: center;
}

.num{
  font-size: 40px;
  font-weight: 600;
  color:#666;
}

.num-label{
  color:#999;
  font-size: 12px;
}

.num2{
  font-size: 30px;
  font-weight: 600;
  color:#666;
}

.num3{
  font-size: 20px;
  font-weight: 600;
  color:#666;
}
.label2{
  color:#999;
  font-size: 12px;
  margin-top:12px;
}
.label3{
  margin-top:6px;
  color:#999;
  font-size: 12px;
}
</style>